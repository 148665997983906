import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['hide', 'relayout', 'resize', 'whiteImage', 'whiteText', 'black']
    static values = { fullscreen: Boolean }

    connect() {
        this.fullscreenValue = false
    }

    toggle(event) {
        event.preventDefault()
        this.fullscreenValue = !this.fullscreenValue
    }

    fullscreenValueChanged() {
        this.hideTargets.forEach(target => {
            target.classList.toggle("hidden")
        })

        this.relayoutTargets.forEach(target => {
            target.classList.toggle("pt-24")
            target.classList.toggle("bg-black")
        })

        this.resizeTargets.forEach(target => {
            target.classList.toggle("h-3/5")
            target.classList.toggle("h-full")
        })

        this.whiteImageTargets.forEach(target => {
            target.classList.toggle("image-white")
        })

        this.whiteTextTargets.forEach(target => {
            target.classList.toggle("text-white")
        })

        this.blackTargets.forEach(target => {
            target.classList.toggle("bg-white")
            target.classList.toggle("bg-black")
        })
    }

}