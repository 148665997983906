import { Controller } from "@hotwired/stimulus";

export default class FileInspector extends Controller {
  static targets = ["checksum", "contentLength"];

  inspectFile(e) {
    const file = e.target.files[0];

    if (file == null) {
      this.checksumTarget.value = "";
      this.contentLengthTarget.value = "";
      return;
    }

    this.checksumTarget.value = "loading...";
    this.contentLengthTarget.value = this._getFileContentLength(file);

    this._computeFileMD5Checksum(file)
      .then((md5) => {
        this.checksumTarget.value = md5;
      })
      .catch((err) => {
        this.checksumTarget.value = err;
      });
  }

  copyChecksum() {
    navigator.clipboard.writeText(this.checksumTarget.value);
  }

  copyContentLength() {
    navigator.clipboard.writeText(this.contentLengthTarget.value);
  }

  async _computeFileMD5Checksum(file) {
    const buffer = await file.arrayBuffer();
    const checksum = md5.base64(buffer);
    return checksum;
  }

  _getFileContentLength(file) {
    return file.size.toString();
  }
}
