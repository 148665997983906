import { Controller } from "@hotwired/stimulus";

export default class BrowserTimestamp extends Controller {
  static targets = ["timestamp"];
  static values = {
    epoch: Number,
  };

  connect() {
    const time = new Date(this.epochValue * 1000);
    const yyyy = time.getFullYear();
    const mm = time.getMonth() + 1;
    const dd = time.getDate();
    const HH = time.getHours().toString().padStart(2, "0");
    const MM = time.getMinutes().toString().padStart(2, "0");
    const SS = time.getSeconds().toString().padStart(2, "0");
    const nn = HH < 12 ? "AM" : "PM";
    this.timestampTarget.innerText = `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}${nn}`;
  }
}
