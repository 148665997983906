import { Toggle } from "tailwindcss-stimulus-components"

export default class extends Toggle {
    static targets = ["button"]

    connect() {
        this.toggleClass = this.data.get('class') || 'hidden'
        this.toggleButton = this.data.get('class') || ['transform', 'rotate-180']
    }

    openValueChanged() {
        if (!this.toggleClass) { return }

        this.toggleableTargets.forEach(target => {
            target.classList.toggle(this.toggleClass)
        })

        this.buttonTargets.forEach(target => {
            this.toggleButton.forEach(className => {
                target.classList.toggle(className)
            })

        })
    }
}
