import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['link']

    connect() {
        this.linkTargets.forEach(target => {
            if (target.pathname == window.location.pathname) {
                target.parentElement.classList.add("bg-indigo-55")
            }
        })
    }
}