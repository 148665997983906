import { Controller } from "@hotwired/stimulus";

export default class Video extends Controller {
  static targets = [
    "video",
    "fullbar",
    "shortbar",
    "timecode",
    "currentTime",
    "durationTime",
    "intervalCurrentTime",
    "intervalDurationTime",
    "videoSpeed",
    "videoPlay",
    "videoPause",
    "playPause",
    "pause",
    "loading",
    "videosContainer",
    "fastforward",
    "rewind",
    "videoTab",
    "poiArrival",
    "currentPoi",
    "nextPoi",
    "videoSearch",
  ];

  connect() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var initialPoiName = null;
    if (params.name) {
      initialPoiName = params.name;
      const queries = new URLSearchParams(window.location.search);
      queries.delete("name");
      window.history.replaceState({}, "", `?${queries.toString()}`);
    }
    this.DEFAULT_AUTOSCROLL_RECOVERY_MILLIS = 5000;
    this.videoTimeUpdateLastHandledAt = 0;
    this.connected = true;
    this.loadedVideos = 0;
    this.cameraPosition = params.direction || "front";
    this.currentType = params.type;
    this.currentPoiIndex = null;
    this.intervalTime = null;
    this.scrollingFlag = false;
    this.autoscrollFlag = true;
    this.disableScrollEvent = false;
    this.playing = true;
    this.autoscrollRecoveryTimeout = null;

    const getPointTags = () =>
      this.cameraPosition === "front"
        ? document.querySelectorAll(".point.point-front")
        : document.querySelectorAll(".point.point-rear");

    const getSignalTags = () =>
      this.cameraPosition === "front"
        ? document.querySelectorAll(".signal.signal-front")
        : document.querySelectorAll(".signal.signal-rear");

    const getStationTags = () =>
      this.cameraPosition === "front"
        ? document.querySelectorAll(".station.station-front")
        : document.querySelectorAll(".station.station-rear");

    const getLandmarkTags = () =>
      this.cameraPosition === "front"
        ? document.querySelectorAll(".landmark.landmark-front")
        : document.querySelectorAll(".landmark.landmark-rear");

    this.typeMapping = new Proxy(
      {},
      {
        get(_target, prop, _receiver) {
          switch (prop) {
            case "point":
              return getPointTags();
            case "signal":
              return getSignalTags();
            case "station":
              return getStationTags();
            case "landmark":
              return getLandmarkTags();
            default:
              return [];
          }
        },
      }
    );

    this.syncVideosReference = this.syncVideos.bind(this);
    this.playVideoReference = this.playVideo.bind(this);

    this.videos.forEach((video) =>
      video.addEventListener("canplay", () => {
        video.pause();
        this.loadedVideos += 1;
        if (this.loadedVideos == 3) {
          this.loading.classList.add("hidden");

          this.currentPoiIndex = this.findCurrentIndex(
            Array.from(this.typeMapping[this.currentType]),
            params.timecode
          );
          this.intervalTime = this.updateInterval(
            this.typeMapping,
            this.currentType,
            this.currentPoiIndex,
            this.timecodes
          );

          this.videos.forEach((video) => {
            video.currentTime = parseInt(params.timecode) / 1000 || 0;
          });

          // Event listener for the play/pause
          this.playPause.addEventListener("click", this.playVideoReference);

          // NOTE(ngkaokis): browsers forbid autoplay of unmuted video
          // when accessing page directly with the url but no such
          // restriction on muted video.
          if (this.videos[0].paused) {
            this.videos.forEach((video) => (video.muted = true));
            this.videos.forEach((video) => video.play());
          }

          this.videosContainer.addEventListener(
            "click",
            this.playVideoReference
          );

          this.videoSpeed.addEventListener("click", () => {
            this.videos.forEach((video) => {
              let rate = video.playbackRate;
              if (rate == 0.5) {
                video.playbackRate = 1;
                this.videoSpeed.innerHTML = "1x";
              } else if (rate == 1) {
                video.playbackRate = 1.5;
                this.videoSpeed.innerHTML = "1.5x";
              } else if (rate == 1.5) {
                video.playbackRate = 2;
                this.videoSpeed.innerHTML = "2x";
              } else {
                video.playbackRate = 0.5;
                this.videoSpeed.innerHTML = "0.5x";
              }
            });
          });

          // Event listener for the fastforward and rewind
          this.fastforward.addEventListener("click", () =>
            this.videos.forEach((video) => (video.currentTime += 10))
          );
          this.rewind.addEventListener("click", () =>
            this.videos.forEach((video) => (video.currentTime -= 10))
          );

          // Event listener for the progress bar
          ["input", "change"].forEach((evt) =>
            this.fullbar.addEventListener(evt, () => {
              var time =
                this.videos[0].duration *
                (this.fullbar.value / this.fullbar.max);
              this.videos.forEach((video) => (video.currentTime = time));
            })
          );

          ["input", "change"].forEach((evt) =>
            this.shortbar.addEventListener(evt, () => {
              var time =
                ((this.intervalTime[1] - this.intervalTime[0]) / 1000) *
                  (this.shortbar.value / this.shortbar.max) +
                this.intervalTime[0] / 1000;
              this.videos.forEach((video) => (video.currentTime = time));
            })
          );

          // Pause the video when the slider handle is being dragged
          [this.shortbar, this.fullbar].forEach((bar) =>
            ["pointerdown"].forEach((evt) =>
              bar.addEventListener(evt, () => {
                this.videos.forEach((video) => video.pause());
              })
            )
          );

          [this.shortbar, this.fullbar].forEach((bar) =>
            ["pointerup", "touchend"].forEach((evt) =>
              bar.addEventListener(evt, () => {
                if (this.playing) this.videos.forEach((video) => video.play());
              })
            )
          );

          [this.shortbar, this.fullbar].forEach((bar) =>
            ["input", "change"].forEach((evt) =>
              bar.addEventListener(evt, this.updateProgressBar.bind(this))
            )
          );

          this.videos.forEach((video) => {
            video.addEventListener("timeupdate", () => {
              if (!this.connected) {
                // NOTE: Ensure not extra update upon disconnected
                return;
              }

              // NOTE: Behave like a throttling but shared among
              // timeupdate events of all videos as logic is not
              // video-specific
              const nowMillis = Date.now();
              if (nowMillis - this.videoTimeUpdateLastHandledAt < 500) {
                return;
              }
              this.videoTimeUpdateLastHandledAt = nowMillis;

              // Update full length
              this.fullbar.value =
                (this.fullbar.max / this.videos[0].duration) *
                this.videos[0].currentTime;

              this.currentTime.innerHTML = new Date(
                this.videos[0].currentTime * 1000
              )
                .toISOString()
                .substring(14, 19);

              this.durationTime.innerHTML = new Date(
                this.videos[0].duration * 1000
              )
                .toISOString()
                .substring(14, 19);

              // Update interval
              let oldCameraPosition = this.cameraPosition;
              let oldType = this.currentType;
              this.updateCurrentTypeFromUrl();

              let oldPoiIndex = this.currentPoiIndex;
              this.currentPoiIndex = this.findCurrentIndex(
                Array.from(this.typeMapping[this.currentType]),
                this.videos[0].currentTime * 1000
              );

              if (
                oldCameraPosition !== this.cameraPosition ||
                oldPoiIndex !== this.currentPoiIndex ||
                oldType !== this.currentType
              ) {
                this.intervalTime = this.updateInterval(
                  this.typeMapping,
                  this.currentType,
                  this.currentPoiIndex,
                  this.timecodes
                );
              }

              let intervalCurrent =
                this.videos[0].currentTime - this.intervalTime[0] / 1000;

              let intervalDuration =
                (this.intervalTime[1] - this.intervalTime[0]) / 1000;

              this.intervalCurrentTime.innerHTML = new Date(
                intervalCurrent * 1000
              )
                .toISOString()
                .substring(14, 19);

              this.intervalDurationTime.innerHTML = new Date(
                intervalDuration * 1000
              )
                .toISOString()
                .substring(14, 19);

              this.shortbar.value =
                (this.shortbar.max / intervalDuration) * intervalCurrent;

              this.updateURL(this.videos[0].currentTime, this.currentType);
              this.updatePoiArrival(
                this.typeMapping,
                this.currentType,
                this.currentPoiIndex,
                this.videos[0].currentTime
              );
              this.updateProgressBar();
              this.scrollActivePoi();
            });
          });

          this.videos.forEach((video) => {
            video.addEventListener("waiting", this.syncVideosReference);
          });

          Array.from(this.timecodes).forEach((timecode) => {
            timecode.addEventListener("click", () => {
              this.videos.forEach(
                (video) => (video.currentTime = parseInt(timecode.value) / 1000)
              );
            });
          });
          Array.from(getPointTags()).forEach((point, index) => {
            point.addEventListener("click", () => {
              this.currentType = "point";
              this.currentPoiIndex = index;
              this.intervalTime = this.updateInterval(
                this.typeMapping,
                this.currentType,
                this.currentPoiIndex,
                this.timecodes
              );
              this.trackVideoView(
                this.currentType,
                point.dataset.name,
                this.intervalTime[0].toString()
              );
            });
          });
          Array.from(getSignalTags()).forEach((signal, index) => {
            signal.addEventListener("click", () => {
              this.currentType = "signal";
              this.currentPoiIndex = index;
              this.intervalTime = this.updateInterval(
                this.typeMapping,
                this.currentType,
                this.currentPoiIndex,
                this.timecodes
              );
              this.trackVideoView(
                this.currentType,
                signal.dataset.name,
                this.intervalTime[0].toString()
              );
            });
          });
          Array.from(getStationTags()).forEach((station, index) => {
            station.addEventListener("click", () => {
              this.currentType = "station";
              this.currentPoiIndex = index;
              this.intervalTime = this.updateInterval(
                this.typeMapping,
                this.currentType,
                this.currentPoiIndex,
                this.timecodes
              );
              this.trackVideoView(
                this.currentType,
                station.dataset.name,
                this.intervalTime[0].toString()
              );
            });
          });
          Array.from(getLandmarkTags()).forEach((landmark, index) => {
            landmark.addEventListener("click", () => {
              this.currentType = "landmark";
              this.currentPoiIndex = index;
              this.intervalTime = this.updateInterval(
                this.typeMapping,
                this.currentType,
                this.currentPoiIndex,
                this.timecodes
              );
              this.trackVideoView(
                this.currentType,
                landmark.dataset.name,
                this.intervalTime[0].toString()
              );
            });
          });

          // Autoscroll feature
          Array.from(this.videoTab).forEach((tab) => {
            tab.addEventListener("scroll", () => {
              this.scrollingFlag = true;
              this.autoscrollFlag = false;
              if (this.autoscrollRecoveryTimeout != null) {
                clearTimeout(this.autoscrollRecoveryTimeout);
              }
              this.autoscrollRecoveryTimeout = setTimeout(() => {
                this.autoscrollFlag = true;
              }, this.DEFAULT_AUTOSCROLL_RECOVERY_MILLIS);
            });
          });
        }
      })
    );
    this.trackVideoView(this.currentType, initialPoiName, params.timecode);
  }

  disconnect() {
    this.connected = false;
  }

  findCurrentIndex(pois, timecode) {
    const index = pois
      .slice()
      .reverse()
      .findIndex((element) => timecode >= element.value);
    return index >= 0 ? pois.length - index - 1 : index;
  }

  updateInterval(typeMapping, currentType, currentPoiIndex, timecodes) {
    var currentElement = typeMapping[currentType][currentPoiIndex];
    var nextElement = typeMapping[currentType][currentPoiIndex + 1];

    const currentPoiName = currentElement?.dataset.name ?? "";
    this.currentPoi.innerText = currentPoiName;
    this.currentPoi.title = currentPoiName;

    const nextPoiName = nextElement?.dataset.name ?? "";
    this.nextPoi.innerText = nextPoiName;
    this.nextPoi.title = nextPoiName;

    Array.from(timecodes).forEach((timecode) => {
      timecode.classList.remove("active_poi");
    });
    if (currentElement) {
      currentElement.classList.add("active_poi");
    }

    return [
      currentElement?.value ?? 0,
      nextElement?.value ?? this.videos[0].duration * 1000,
    ];
  }

  scrollActivePoi() {
    const activePoi = document.querySelector(".active_poi");
    if (activePoi == null) {
      return;
    }
    if (this.autoscrollFlag) {
      this.disableScrollEvent = true;
      activePoi.scrollIntoView(true);
      setTimeout(() => {
        this.scrollingFlag = false;
        this.disableScrollEvent = false;
      }, 100);
    }
  }

  updateURL(currentTime, currentType) {
    let timecode = parseInt(currentTime) * 1000;
    const readParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (timecode == null || currentType == null) {
      // NOTE: URL change happens before controller disconnects,
      // seems unpredictable under stimulus lifecycle; upon URL
      // change, `currentType` becomes null and we should stop
      // update URL
      return;
    }
    if (readParams.timecode != timecode || readParams.type != currentType) {
      let params = new URLSearchParams(window.location.search);
      params.set("timecode", timecode);
      params.set("type", currentType);
      window.history.replaceState({}, "", `?${params.toString()}`);
    }
  }

  updatePoiArrival(typeMapping, currentType, currentPoiIndex, currentTime) {
    let timecode = parseInt(currentTime) * 1000;
    let poiArrival = this.poiArrival;
    let messagePeriod = 2000;
    poiArrival.classList.add("invisible");
    try {
      if (
        timecode - typeMapping[currentType][currentPoiIndex].value <=
        messagePeriod
      ) {
        let poiArrivalName =
          typeMapping[currentType][currentPoiIndex].innerText.trim();
        poiArrival.innerText = poiArrivalName;
        poiArrival.classList.remove("invisible");
      }
    } catch (e) {}

    try {
      if (
        typeMapping[currentType][currentPoiIndex + 1].value - timecode <=
        messagePeriod
      ) {
        let poiArrivalName =
          typeMapping[currentType][currentPoiIndex + 1].innerText.trim();
        poiArrival.innerText = poiArrivalName;
        poiArrival.classList.remove("invisible");
      }
    } catch (e) {}
  }

  updateProgressBar() {
    Array.from(this.progressbars).forEach((progressbar) => {
      const min = progressbar.min;
      const max = progressbar.max;
      const val = progressbar.value;

      progressbar.style.backgroundSize =
        ((val - min) * 100) / (max - min) + "% 100%";
    });
  }

  playVideo() {
    this.videos.forEach((video) => {
      if (video.paused == true) {
        video.play();
        this.playing = true;
        this.videoPlay.classList.add("hidden");
        this.videoPause.classList.remove("hidden");
        this.pause.classList.add("hidden");
        this.loading.classList.add("hidden");
      } else {
        video.pause();
        this.playing = false;
        this.videoPlay.classList.remove("hidden");
        this.videoPause.classList.add("hidden");
        this.pause.classList.remove("hidden");
      }
    });
  }

  updateCurrentTypeFromUrl() {
    let params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.cameraPosition = params.direction || "front";
    this.currentType = params.type;
  }

  syncVideos(e) {
    this.loading.classList.remove("hidden");
    this.playPause.removeEventListener("click", this.playVideoReference);
    this.videosContainer.removeEventListener("click", this.playVideoReference);
    e.target.play();
    e.target.removeEventListener("waiting", this.syncVideosReference);
    this.videos.forEach((videoToPause) => {
      if (videoToPause != e.target) videoToPause.pause();
    });
    e.target.addEventListener(
      "playing",
      () => {
        this.videos.forEach((video) => {
          video.play();
          video.addEventListener("waiting", this.syncVideosReference);
        });
        this.loading.classList.add("hidden");
        this.playPause.addEventListener("click", this.playVideoReference);
        this.videosContainer.addEventListener("click", this.playVideoReference);
      },
      { once: true }
    );
  }

  searchPois(event) {
    const q = event.target.value;
    this.dispatch("searchPois", { detail: { q } });
  }

  trackVideoView(type, name, timecode) {
    fetch("/analytics/video", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        track_id: location.pathname.split("/")[2],
        type: type,
        timecode: timecode,
        poi: name,
      }),
    }).catch(console.error);
  }

  get videos() {
    return this.videoTargets;
  }

  get fullbar() {
    return this.fullbarTarget;
  }

  get shortbar() {
    return this.shortbarTarget;
  }

  get progressbars() {
    return [this.fullbar, this.shortbar];
  }

  get timecodes() {
    return this.timecodeTargets;
  }

  get currentTime() {
    return this.currentTimeTarget;
  }

  get durationTime() {
    return this.durationTimeTarget;
  }

  get intervalCurrentTime() {
    return this.intervalCurrentTimeTarget;
  }

  get intervalDurationTime() {
    return this.intervalDurationTimeTarget;
  }

  get videoSpeed() {
    return this.videoSpeedTarget;
  }

  get videoPlay() {
    return this.videoPlayTarget;
  }

  get videoPause() {
    return this.videoPauseTarget;
  }

  get playPause() {
    return this.playPauseTarget;
  }

  get pause() {
    return this.pauseTarget;
  }

  get loading() {
    return this.loadingTarget;
  }

  get videosContainer() {
    return this.videosContainerTarget;
  }

  get videoTab() {
    return this.videoTabTargets;
  }

  get poiArrival() {
    return this.poiArrivalTarget;
  }

  get currentPoi() {
    return this.currentPoiTarget;
  }

  get nextPoi() {
    return this.nextPoiTarget;
  }

  get fastforward() {
    return this.fastforwardTarget;
  }

  get rewind() {
    return this.rewindTarget;
  }

  get videoSearch() {
    return this.videoSearchTarget;
  }
}
