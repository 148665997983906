// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import { Application } from "@hotwired/stimulus";
// Import and register all TailwindCSS Components
import { Modal } from "tailwindcss-stimulus-components";
import Turbolinks from "turbolinks";
import "channels";

const application = Application.start();
application.warnings = true;
application.debug = false;
window.Stimulus = application;

import BrowserTimestamp from "../components/browser-timestamp";
import ButtonToggle from "../components/buttonToggle";
import CurrentPoi from "../components/current-poi";
import CurrentTrack from "../components/current-track";
import Dropdown from "../components/dropdown";
import FileInspector from "../components/file-inspector";
import Fullscreen from "../components/fullscreen";
import MockDeepLinkPicker from "../components/mock-deep-link-picker";
import Tabs from "../components/tabs";
import Toggle from "../components/toggle";
import VideoCameraPositionToggle from "../components/video-camera-position-toggle";
import VideoPoiList from "../components/video-poi-list";
import Video from "../components/video";

application.register("browser-timestamp", BrowserTimestamp);
application.register("button-toggle", ButtonToggle);
application.register("current-poi", CurrentPoi);
application.register("current-track", CurrentTrack);
application.register("dropdown", Dropdown);
application.register("file-inspector", FileInspector);
application.register("fullscreen", Fullscreen);
application.register("mock-deep-link-picker", MockDeepLinkPicker);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("toggle", Toggle);
application.register("video-camera-position-toggle", VideoCameraPositionToggle);
application.register("video-poi-list", VideoPoiList);
application.register("video", Video);

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// import all images
require.context("../images", true);
