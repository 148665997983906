import { Controller } from "@hotwired/stimulus";

export default class VideoPoiList extends Controller {
  static targets = ["poiItem", "emptyLabel"];

  connect() {
    let params = new URLSearchParams(window.location.search);
    this._searchString = "";
    this._cameraPosition = params.get("direction") || "front";
    this._filterPois({
      cameraPosition: this._cameraPosition,
      searchString: this._searchString,
    });
  }

  searchPois(event) {
    const { q } = event.detail;
    this._searchString = q;
    this._filterPois({
      cameraPosition: this._cameraPosition,
      searchString: this._searchString,
    });
  }

  filterPois(event) {
    const { cameraPosition } = event.detail;
    this._cameraPosition = cameraPosition;
    this._filterPois({
      cameraPosition: this._cameraPosition,
      searchString: this._searchString,
    });
  }

  _matchCameraPosition({ cameraPosition, poiDirection }) {
    let matched = true;
    switch (poiDirection) {
      case "front":
        matched = cameraPosition == "front";
        break;
      case "back":
        matched = cameraPosition == "rear";
        break;
    }
    return matched;
  }

  _filterPois({ cameraPosition, searchString }) {
    let isEmpty = true;
    this.poiItemTargets.forEach((poiItem) => {
      const { direction: poiDirection, name: poiName } = poiItem.dataset;

      const matchCameraPosition = this._matchCameraPosition({
        cameraPosition,
        poiDirection,
      });
      const matchSearchString = poiName
        .toLowerCase()
        .includes(searchString.toLowerCase());

      if (matchCameraPosition && matchSearchString) {
        poiItem.classList.remove("hidden");
        isEmpty = false;
      } else {
        poiItem.classList.add("hidden");
      }
    });
    if (isEmpty) {
      this.emptyLabelTarget.classList.remove("hidden");
    } else {
      this.emptyLabelTarget.classList.add("hidden");
    }
  }
}
