import { Tabs } from "tailwindcss-stimulus-components"

export default class extends Tabs {
    connect() {
        this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
        this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
        if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
        this.index = this.data.get('track') || this.data.get('poi') || 0
        this.type = this.data.get('type')
        this.showTab()
    }

    change(event) {
        event.preventDefault()
        // If target specifies an index, use that
        if (event.currentTarget.dataset.index) {
            this.index = event.currentTarget.dataset.index

            // If target specifies an id, use that
        } else if (event.currentTarget.dataset.id) {
            this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

            // Otherwise, use the index of the current target
        } else {
            this.index = this.tabTargets.indexOf(event.currentTarget)
        }
        this.updateURL()
        window.dispatchEvent(new CustomEvent('tsc:tab-change'))
    }

    updateURL() {
        const poiMapping = {
            0: 'point',
            1: 'signal',
            2: 'station',
            3: 'landmark'
        }

        if (this.type == 'poi') {
            let params = new URLSearchParams(window.location.search);
            params.set("type", poiMapping[this.index])
            window.history.replaceState({}, '', `?${params.toString()}`)
        }
    }

}
