import { Controller } from "@hotwired/stimulus";

export default class MockDeepLinkPicker extends Controller {
  static targets = ["link", "option"];

  updateDeepLink(e) {
    this._updateDeepLink(e.target.value);
  }

  openLink() {
    window.open(this.linkTarget.value);
  }

  copyLink() {
    navigator.clipboard.writeText(this.linkTarget.value);
  }

  connect() {
    this._updateDeepLink(this.optionTargets[0].value);
  }

  _updateDeepLink(s) {
    this.linkTarget.value = s;
  }
}
