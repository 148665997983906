import { Toggle } from "tailwindcss-stimulus-components"

export default class extends Toggle {

    connect() {
        this.toggleClass = this.data.get('class') || 'hidden'
        let params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        this.direction = params.direction || 'front'
        this.type = this.data.get('type')
    }

    openValueChanged() {
        super.openValueChanged()
        if (this.type == 'direction') {
            this.direction = this.direction === 'front' ? 'rear' : 'front'
            let params = new URLSearchParams(window.location.search)
            params.set("direction", this.direction)
            window.history.replaceState({}, '', `?${params.toString()}`)
        }
    }
}
