import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['link']

    connect() {
        this.linkTargets.forEach(target => {
            if (target.href == window.location.href) {
                target.parentElement.classList.add("text-indigo-55", "border-b-2", "border-indigo", "border-solid")
            }
            else if (!window.location.search && target.href.includes("point")) {
                target.parentElement.classList.add("text-indigo-55", "border-b-2", "border-indigo", "border-solid")
            }
            else {
                target.parentElement.classList.add("text-gray-500")
            }
        })
    }
}
